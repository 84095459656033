<template>
  <div>
    <el-tabs v-if="$route.path.indexOf('_editor') === -1">
<!--      <el-row :gutter="10">-->
<!--          <el-col :span="3">-->
            <el-button type="primary" icon="plus" @click="addPreferential">添加AB实验活动</el-button>
<!--          </el-col>-->

<!--        <el-col>-->
          <el-select v-model="selectType" placeholder="请选择类型" clearable @change="getDataList()">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input placeholder="请输入优惠活动名称" v-model="name" style='width:400px'>
            <el-button slot="append" icon="el-icon-search" @click="dataSearch"></el-button>
          </el-input>
<!--        </el-col>-->

<!--      </el-row>-->

      <el-table :data="dataList" v-loading="loading" >
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="activity_title" label="实验名称" width="180"></el-table-column>
        <el-table-column prop="activity_subtitle" label="实验参数" width="180"></el-table-column>
        <el-table-column prop="type" label="页面类型" width="160">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type == 'abindex'">AB实验首页</el-tag>
            <el-tag v-if="scope.row.type == 'applecare'">AppleCare+</el-tag>
            <el-tag v-if="scope.row.type == 'tradein'">Trade-in</el-tag>
            <el-tag v-if="scope.row.type == 'accy'">Accy+</el-tag>
            <el-tag v-if="scope.row.type == 'handprice'">到手价实验</el-tag>
            <el-tag v-if="scope.row.type == 'productchange'">产品切换实验</el-tag>
            <el-tag v-if="scope.row.type == 'orderpayload'">订单结算页加载</el-tag>
            <el-tag v-if="scope.row.type == 'kefuabtest'">客服AB实验</el-tag>
            <el-tag v-if="scope.row.type == 'service'">服务</el-tag>
            <el-tag v-if="scope.row.type == 'welfare'">福利</el-tag>
            <!--<el-tag v-if="scope.row.type == 'hotbuy'">热卖</el-tag>-->
            <el-tag v-if="scope.row.type == 'activity'">活动</el-tag>
            <el-tag v-if="scope.row.type == 'selling'">热卖</el-tag>
            <el-tag v-if="scope.row.type == 'receipttype'">默认选中配送方式</el-tag>
            <el-tag v-if="scope.row.type == 'tradeInType'">商品详情页以旧换新折抵金额</el-tag>
            <el-tag v-if="scope.row.type == 'applecareFlow'">AC+购买flow</el-tag>
            <el-tag v-if="scope.row.type == 'paymentType'">支付方式选择</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="page_name" label="小程序模板页面名称" width="150"></el-table-column> -->
        <el-table-column prop="create_time" label="实验开始时间" width="190">
          <template slot-scope="scope">
            <span>{{scope.row.start_time}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="end_time" label="实验结束时间" width="190">
          <template slot-scope="scope">
            <span>{{scope.row.end_time}}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="operating-icons">
              <i class="iconfont icon-edit1" v-if="scope.row.type != 'abindex'" @click="PreferentialEdit(scope.$index, scope.row)"></i>
              <i class="mark iconfont icon-trash-alt1" @click="PreferentialDelete(scope.$index, scope.row)"></i>
            </div>
          </template>
        </el-table-column>

      </el-table>
      <div class="content-padded content-center">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="params.page"
          :page-sizes="[10,20,50]"
          :total="total_count"
          :page-size="params.pageSize"
        ></el-pagination>
      </div>

    </el-tabs>
    <router-view></router-view>

  </div>
</template>

<script>
import {getPreferential, deletePreferential} from '../../../../api/promotions'

export default {
  provide() {
      return {
        refresh: this.getDataList
      }
    },
  data() {
    return {
      name: '',
      loading: false,
      total_count: 0,
      dataList: [],
      params: {
        page: 1,
        pageSize: 20,
      },
      form: {
        id: '',
      },
      selectType: "abindex",
      typeOptions: [
        {
          value: 'abindex',
          label: 'AB实验首页',
        },
        {
          value: 'applecare',
          label: 'AppleCare+',
        },
        {
          value: 'tradein',
          label: 'Trade-in',
        },
        {
          value: 'accy',
          label: 'Accy',
        },
        {
          value: 'handprice',
          label: '到手价实验'
        },
        {
          value: 'productchange',
          label: '产品切换实验'
        },
        {
          value: 'orderpayload',
          label: '订单结算页加载'
        },
        {
          value: 'kefuabtest',
          label: '客服AB实验'
        },
        {
          value: 'receipttype',
          label: '默认选中配送方式'
        },
        {
          value: 'tradeInType',
          label: '商品详情页以旧换新折抵金额'
        },
        {
          value: 'applecareFlow',
          label: 'AC+购买flow'
        },
        {
          value: 'paymentType',
          label: '支付方式选择'
        }
        // {
        //   value: 'service',
        //   label: '服务',
        // },{
        //   value: 'activity',
        //   label: '活动',
        // },{
        //   value: 'selling',
        //   label: '热卖',
        // },{
        //   value: 'welfare',
        //   label: '福利',
        // },
      ],
    }
  },

  methods: {

    dataSearch() {
      this.params.name = this.name
      this.params.page = 1
      this.getDataList()
    },
    getDataList() {
      this.loading = true
      console.log(this.params)
      if ( this.selectType != '' ) {
        this.params.type = this.selectType
      } else {
        this.params.type = [ 'abindex','applecare', 'tradein', 'accy', 'handprice', 'productchange', 'orderpayload', 'kefuabtest']
      }

      getPreferential(this.params).then(response => {
        this.loading = false
        this.dataList = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num
      this.getDataList()
    },
    handleSizeChange(pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getDataList()
    },

    addPreferential () {
       console.log(this.$router.push({path: this.matchHidePage('editor')}))
    },
    PreferentialEdit (index, row) {
      console.log(row)
      this.$router.push({path: this.matchHidePage('editor?id=') + row.id})
    },

    PreferentialDelete (index, row) {
        this.$confirm('此操作将删除该优惠活动, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deletePreferential(row.id).then(response => {
            this.dataList.splice(index, 1)
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 5 * 1000
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },

  },
  mounted() {
    this.getDataList()
  }
}
</script>
